import React from "react"
import { StaticQuery, graphql } from "gatsby"

import TermsSection from "./TermsSection"

export default props => (
  <StaticQuery
  query={graphql`
    query {
      legal {
        replacements {
          key
          value
        }
        terms_conditions {
          heading
          disclaimer
          updated
          items {
            title
            content
          }
        }
      }
    }
  `}
  render={data => <TermsSection data={data} {...props} />}
  />
)
